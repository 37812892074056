import { CCard, CCardBody, CCol, CFormSelect, CRow } from '@coreui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { endPoints } from '../../../../Constants';
import { getAccessToken, getLoggedInUserRole, writeDocumentTitle } from '../../../../Helpers';
import Loader from '../layout/Loader';
import "../../../../Loader.css";
import ReportIcon from '../../../../assets/images/reports.svg';
import UsersIcon from '../../../../assets/images/users.svg';
import CustomersIcon from '../../../../assets/images/Customers.svg';
import SuppliersIcon from '../../../../assets/images/Suppliers.svg';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';

import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, FusionTheme);

const Dashboard = (props) => {
  const [ dashboardDataCount, setDashboardDataCount ] = useState([]);
  const [ reportStatusCountList, setReportStatusCountList ] = useState([]);
  const [ weekData, setWeekData ] = useState([]);
  const [ weekList, setWeekList ] = useState([]);
  const [ opportunityClassList, setOpportunityClassList ] = useState([]);
  const [ opportunityClassStatusList, setOpportunityClassStatusList ] = useState([]);
  const [ siteWiseReportStatusList, setSiteWiseReportStatusList ] = useState([]);
  const [ userRole, setUserRole ] = useState('');
  const [ searchType, setSearchType ] = useState('year');
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let sUserRole = getLoggedInUserRole();
    setUserRole(sUserRole);
  }, [ userRole ]);

  const navigate = useNavigate();
  useEffect(() => {
    writeDocumentTitle(props.title);
    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    setLoading(true);
    /*--------------- get card data --------*/
    axios.get(`${endPoints}dashboard`, config)
      .then((response) => {
        let { dashboardDataCount } = response.data;
        setDashboardDataCount(dashboardDataCount);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }

      })
    /*--------------- get card data --------*/

    /*--------------- get graph data --------*/
    axios.post(`${endPoints}getDashboardGraphData`, { sType: 'year' }, config)
      .then((response) => {
        let { aOverAllReportStatus, aWeekData, aWeekList, OpportunityClasses, aOpportunityClassWiseReportStatus, aSiteWiseReportStatus } = response.data;
        console.log({ aWeekList });
        setReportStatusCountList(aOverAllReportStatus);
        setWeekData(aWeekData);
        setWeekList(aWeekList);
        setOpportunityClassList(OpportunityClasses);
        setOpportunityClassStatusList(aOpportunityClassWiseReportStatus);
        setSiteWiseReportStatusList(aSiteWiseReportStatus);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
        setLoading(false);
      })
    /*--------------- get graph data --------*/
  }, []);

  /*----------------- Report Status --------------------*/
  // STEP 2 - Chart Data
  const chartData = [
    {
      label: "Closed",
      value: reportStatusCountList.closed,
      color: '#109618'
    },
    {
      label: "In Progress",
      value: reportStatusCountList.in_progress,
      color: '#ff9900'
    },
    {
      label: "Open",
      value: reportStatusCountList.open,
      color: '#d633ff'
    }
  ];

  // STEP 3 - Creating the JSON object to store the chart configurations
  const chartConfigs = {
    type: "doughnut2d", // The chart type
    width: "100%", // Width of the chart
    height: "400", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "Report Status",
        subCaption: searchType && searchType === 'year' ? 'Last 365 Days' : (searchType === 'month' ? 'Last 30 Days' : 'Last 7 Days'),
        //numberPrefix: "$",
        //defaultCenterLabel: "Total Report Status: $64.08K",
        centerLabel: "$label Status: $value",
        decimals: "0",
        theme: "fusion"
      },
      // Chart Data
      data: chartData
    }
  };
  /*----------------- Report Status --------------------*/


  const chartConfigsReportStatus = {
    type: "scrollcolumn2d",
    chart: {
      caption: "Monthly Average Report",
      subcaption: searchType && searchType === 'year' ? 'Last 365 Days' : (searchType === 'month' ? 'Last 30 Days' : 'Last 7 Days'),
      yaxisname: "Report",
      numvisibleplot: "40",
      labeldisplay: "auto",
      theme: "fusion",
    },
    categories: [
      {
        category: weekData ?? ''
      }
    ],
    dataset: weekList
  };
  /*----------------- Report Status By Site --------------------*/
  const dataSource = {
    type: "multilevelpie", // The chart type
    chart: {
      caption: "Site Wise Report Status",
      subcaption: searchType && searchType === 'year' ? 'Last 365 Days' : (searchType === 'month' ? 'Last 30 Days' : 'Last 7 Days'),
      basefontsize: "10",
      showplotborder: "1",
      piefillalpha: "60",
      hoverfillcolor: "#CCCCCC",
      //numberprefix: "$",
      // plottooltext:
      //   "<b>$label</b> was <b>$value</b>, which was $percentValue of parent category",
      plottooltext:
        `<b>$value</b>, which was $percentValue of parent category`,
      theme: "fusion",
      pieBorderThickness: "2",
      
    },

    category: [
      {
        label: "Site",
        tooltext: "Please hover over a site category to see details",
        color: "#ffffff",
        value: "150",
        category: siteWiseReportStatusList
      }
    ]
  };


  /*----------------- Report Status By Site --------------------*/


  /*----------------- Opportunity class wise Report Status --------------------*/
  const dataSourceStacked = {

    chart: {
      caption: "Opportunity Class Wise Report Status",
      subcaption: searchType && searchType === 'year' ? 'Last 365 Days' : (searchType === 'month' ? 'Last 30 Days' : 'Last 7 Days'),
      //numbersuffix: " TWh",
      numVisiblePlot: "20",
      showsum: "1",
      plottooltext:
        "$label <b>$dataValue</b> $seriesName",
      theme: "fusion",
      drawcrossline: "1",
      flatScrollBars: "1",
      scrollheight: "10",
      //lineThickness: "1",
      numvisibleplot: "10",
      valueBorderPadding: '0.2',

    },
    categories: [
      {
        category: opportunityClassList
      }
    ],
    dataset: opportunityClassStatusList,
    chartConfig: {
      plotSpacePercent: '500', // Adjust the value to set the thickness of the stacked column
    },
  };

  const graphFilter = (e) => {
    let sType = e.target.value;
    setSearchType(sType);
    /*---------------- get access token --------------*/
    const accessToken = getAccessToken();
    if (!accessToken) {
      localStorage.removeItem('isAdminLoggedIn');
      navigate(`${process.env.PUBLIC_URL}/`);
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    /*---------------- get access token --------------*/
    setLoading(true);
    let inputValue = { sType };

    axios.post(`${endPoints}getDashboardGraphData`, inputValue, config)
      .then((response) => {
        let { aOverAllReportStatus, aWeekData, aWeekList, OpportunityClasses, aOpportunityClassWiseReportStatus, aSiteWiseReportStatus } = response.data;
        setReportStatusCountList(aOverAllReportStatus);
        setWeekData(aWeekData);
        setWeekList(aWeekList);
        setOpportunityClassList(OpportunityClasses);
        setOpportunityClassStatusList(aOpportunityClassWiseReportStatus);
        setSiteWiseReportStatusList(aSiteWiseReportStatus);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('isAdminLoggedIn');
          navigate(`${process.env.PUBLIC_URL}/`);
        }
        setLoading(false);
      })
  }
  return (
    <>
      {
        loading ? <Loader />
          :

          <>
            <CRow className='graphFilterDrop'>
              <CCol xs={10}></CCol>
              <CCol xs={2}>
                <div className="mb-3">
                  {/* <CFormLabel htmlFor="status"><strong>Status</strong><span className="formError">*</span></CFormLabel> */}
                  <CFormSelect aria-label="Default select example" name="status" id="status" value={searchType} onChange={(e) => { graphFilter(e) }}>
                    <option value="week">Last 7 Days</option>
                    <option value="month">Last 30 Days</option>
                    <option value="year">Last 365 Days</option>
                  </CFormSelect>
                </div>
              </CCol>
            </CRow>

            {
              userRole && userRole === 'superadmin' ?
                <CRow className='mb-4 dashboardStatus'>
                  <CCol xs={12}>
                    <CCard className="">
                      <CCardBody>
                        <CRow>
                          {[
                            { statusIcon: ReportIcon, width: '41px', className: 'textBlue statusValue', textColor: 'white', name: 'Reports', iTotalCount: dashboardDataCount.iTotalReports,respectiveURL:'admin/reports' },
                            { statusIcon: UsersIcon, width: '51px', className: 'textYellowX statusValue', textColor: 'white', name: 'Users', iTotalCount: dashboardDataCount.iTotalUsers,respectiveURL:'admin/users' },
                            { statusIcon: CustomersIcon, width: '51px', className: 'textGreenX statusValue', textColor: 'white', name: 'Customers', iTotalCount: dashboardDataCount.iTotalCustomers,respectiveURL:'admin/customers' },
                            { statusIcon: SuppliersIcon, width: '60px', className: 'textRedX statusValue', textColor: 'white', name: 'Suppliers', iTotalCount: dashboardDataCount.iTotalSuppliers,respectiveURL:'admin/suppliers' },

                          ].map((item, index) => (
                            <CCol xs={3} key={index + 1}>
                               <NavLink to={`${process.env.PUBLIC_URL}/${item.respectiveURL}`} className="dashboardRedirect">
                                  <div className='cardStatusDiv'>
                                    <div className='statusIcon'>
                                       <img src={item.statusIcon} style={{ width: item.width }} />
                                    </div>
                                    <div className='statusInfo'>
                                      <h6 className={item.className}>{item.iTotalCount}</h6>
                                      <p className='statusName'> {item.name}</p>
                                    </div>
                                  </div>
                               </NavLink>
                            </CCol>
                          ))}
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
                : null
            }

            <CRow>
              {
                //reportStatusCountList.closed > 0 || reportStatusCountList.in_progress > 0 || reportStatusCountList.open > 0
                reportStatusCountList  && 
                <CCol xs={12} xl={4}>
                  <CCard className="marginBtmMobile">
                    <CCardBody>
                      {
                         <ReactFC {...chartConfigs} />
                      }
                    </CCardBody>
                  </CCard>
                </CCol>
              }
              <CCol xs={12} xl={8}>
                <CCard className="">
                  <CCardBody>
                    {
                      weekList && Array.isArray(weekList) && weekList.length > 0 &&
                      <ReactFC
                        type="scrollcolumn2d"
                        width="100%"
                        height="400"
                        dataFormat="JSON"
                        dataSource={chartConfigsReportStatus}
                      />
                    }
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow className="my-4">
              <CCol xs={12} xl={8}>
                <CCard className="mb-4">
                  <CCardBody>
                    {
                      opportunityClassStatusList && Array.isArray(opportunityClassStatusList) && opportunityClassStatusList.length > 0 &&
                      <ReactFC
                        type="scrollstackedcolumn2d"
                        width="100%"
                        height="400"
                        dataFormat="JSON"
                        dataSource={dataSourceStacked}
                      />
                    }
                  </CCardBody>
                </CCard>
              </CCol>
              {
              siteWiseReportStatusList && Array.isArray(siteWiseReportStatusList) && siteWiseReportStatusList.length > 0 &&
              <CCol xs={12} xl={4}>
                <CCard className="mb-4">
                  <CCardBody>
                   
                      <ReactFC
                        type="multilevelpie"
                        width="100%"
                        height="378"
                        dataFormat="json"
                        dataSource={dataSource}
                      />
                    

                    <div className='nestedLegendWrap'>
                      <ul className="nestedLegend">
                        <li className="">
                          <span className="squareBox legendYellow"></span>Client Site
                    </li>
                        <li className="">
                          <span className="squareBox legendGreen"></span>Supplier Site
                    </li>
                        <li className="">
                          <span className="squareBox legendPink"></span>Rysco Site
                    </li>
                        <li className="">
                          <span className="squareBox legendBlue"></span>Open Road
                    </li>

                      </ul>
                    </div>

                  </CCardBody>
                </CCard>
              </CCol>
             }
            </CRow>

          </>
      }
    </>
  )
}

export default Dashboard
